<template>
  <b-container fluid>
    <b-row v-if="rule == null && error == null" class="mt-5">
      <b-col class="text-center">
        <b-spinner label="Spinning"></b-spinner>
      </b-col>
    </b-row>

    <template v-if="rule">
      <b-row>
        <b-col>
          <b-card :header="'Rule: ' + rule.Description" class="mt-4">
            <b-table-simple small striped>
              <b-thead>
              </b-thead>
              
              <b-tbody>
                <b-tr>
                  <b-th>Share:</b-th>
                  <b-td>{{ rule.ShareRate|percentage }}</b-td>
                </b-tr>

                <b-tr>
                  <b-th>Split:</b-th>
                  <b-td>{{ rule.SplitRate|percentage }}</b-td>
                </b-tr>

                <b-tr>
                  <b-th>Reference / Beneficiary:</b-th>
                  <b-td>
                    <router-link :to="{ name: 'accounting-statement-spec', params: { id: rule.StatementReference } }">
                      {{ rule.StatementReference }} - 
                      {{ rule.StatementSpec.Beneficiary.FullName }}
                    </router-link>
                  </b-td>
                </b-tr>

              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-card header="Groups" class="mt-4">
            <b-table-simple small>
              <b-thead>
                <b-tr>
                  <b-th>First period</b-th>
                  <b-th>Last period</b-th>
                  <b-th>Group</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="member in rule.ReaccountRuleGroupMembers" :key="member.TransactionReaccountRuleGroupMemberID">
                  <b-td>{{ member.FirstValidAccountingPeriodID|formatAccountingPeriod }}</b-td>
                  <b-td>{{ member.LastValidAccountingPeriodID|formatAccountingPeriod }}</b-td>
                  <b-td>
                    <router-link :to="{ name: 'accounting-rule-group', params: { id: member.TransactionReaccountRuleGroupID } }">
                      {{ member.RuleGroup.Description }}
                    </router-link>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
  export default {
    name: 'AccountingRule',
    components: {
    },
    data () {
      return {
        rule: null,
        error: null,
      }
    },
    props: {
      id: String
    },
    methods: {
    },
    mounted () {
      this.$http.get('accounting/rules/' + this.id, {
          
        })
        .then(response => {
          this.rule = response.data
        })
        .catch(() => {
          this.$notify({
            type: 'danger',
            text: 'Er ging iets mis bij het laden'
          });
        });
    }
  }
</script>