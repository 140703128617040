var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _vm.rule == null && _vm.error == null
        ? _c(
            "b-row",
            { staticClass: "mt-5" },
            [
              _c(
                "b-col",
                { staticClass: "text-center" },
                [_c("b-spinner", { attrs: { label: "Spinning" } })],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.rule
        ? [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "mt-4",
                        attrs: { header: "Rule: " + _vm.rule.Description }
                      },
                      [
                        _c(
                          "b-table-simple",
                          { attrs: { small: "", striped: "" } },
                          [
                            _c("b-thead"),
                            _c(
                              "b-tbody",
                              [
                                _c(
                                  "b-tr",
                                  [
                                    _c("b-th", [_vm._v("Share:")]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("percentage")(
                                            _vm.rule.ShareRate
                                          )
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "b-tr",
                                  [
                                    _c("b-th", [_vm._v("Split:")]),
                                    _c("b-td", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("percentage")(
                                            _vm.rule.SplitRate
                                          )
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "b-tr",
                                  [
                                    _c("b-th", [
                                      _vm._v("Reference / Beneficiary:")
                                    ]),
                                    _c(
                                      "b-td",
                                      [
                                        _c(
                                          "router-link",
                                          {
                                            attrs: {
                                              to: {
                                                name:
                                                  "accounting-statement-spec",
                                                params: {
                                                  id:
                                                    _vm.rule.StatementReference
                                                }
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.rule.StatementReference
                                                ) +
                                                " - " +
                                                _vm._s(
                                                  _vm.rule.StatementSpec
                                                    .Beneficiary.FullName
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  [
                    _c(
                      "b-card",
                      { staticClass: "mt-4", attrs: { header: "Groups" } },
                      [
                        _c(
                          "b-table-simple",
                          { attrs: { small: "" } },
                          [
                            _c(
                              "b-thead",
                              [
                                _c(
                                  "b-tr",
                                  [
                                    _c("b-th", [_vm._v("First period")]),
                                    _c("b-th", [_vm._v("Last period")]),
                                    _c("b-th", [_vm._v("Group")])
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-tbody",
                              _vm._l(
                                _vm.rule.ReaccountRuleGroupMembers,
                                function(member) {
                                  return _c(
                                    "b-tr",
                                    {
                                      key:
                                        member.TransactionReaccountRuleGroupMemberID
                                    },
                                    [
                                      _c("b-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatAccountingPeriod")(
                                              member.FirstValidAccountingPeriodID
                                            )
                                          )
                                        )
                                      ]),
                                      _c("b-td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("formatAccountingPeriod")(
                                              member.LastValidAccountingPeriodID
                                            )
                                          )
                                        )
                                      ]),
                                      _c(
                                        "b-td",
                                        [
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: {
                                                  name: "accounting-rule-group",
                                                  params: {
                                                    id:
                                                      member.TransactionReaccountRuleGroupID
                                                  }
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    member.RuleGroup.Description
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }